import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import { useNavigate, useParams } from "react-router-dom";
import { message } from "antd";
import { useSelector } from "react-redux";
import axios from "axios";
import VerifiedIcon from "@mui/icons-material/Verified";
import StickyFooter from "../components/Home/StickyFooter";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import InfoIcon from "@mui/icons-material/Info";
import CancelIcon from "@mui/icons-material/Cancel";
import "./ProductInfo.css";

const ProductInfo = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [playerCheck, setPlayerCheck] = useState(null);
  const [product, setProduct] = useState(0);
  const [showImage, setShowImage] = useState(0);
  const [error, setError] = useState(false);
  const [mode, setMode] = useState("UPI");
  const [orderId, setOrderId] = useState(false);
  const [loader, setLoader] = useState(false);
  const [balance, setBalance] = useState("");
  //!NEW STATE
  const [playerId, setPlayerId] = useState("");
  const [amount, setAmount] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [productId, setProductId] = useState("");
  //! API BASED
  const [userId, setUserId] = useState("");
  const [zoneId, setZoneId] = useState("");
  const [popup, setPopup] = useState(false);

  const [popupVisible, setPopupVisible] = useState(false);

  const togglePopup = () => {
    setPopupVisible(!popupVisible);
  };

  function setPriceAndId(amount) {
    const price = product?.cost?.find((item) => item.amount === amount)?.price;
    setSelectedPrice(price);

    const id = product?.cost?.find((item) => item.amount === amount)?.id;
    setProductId(id);
  }

  const getProduct = async () => {
    try {
      const res = await axios.post("/api/product/get-product-by-name", {
        name: params.name,
      });
      if (res.data.success) {
        setProduct(res.data.data);
        // Set default values from the first item in the cost array
        const defaultAmount = res.data.data?.cost?.[0]?.amount;
        const defaultPrice = res.data.data?.cost?.[0]?.price;
        const defaultId = res.data.data?.cost?.[0]?.id;
        setAmount(defaultAmount);
        setSelectedPrice(defaultPrice);
        setProductId(defaultId);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProduct();
  }, [params?.name]);

  const generateOrderId = () => {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2); // Last two digits of the year
    const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    const randomNum = Math.floor(1000 + Math.random() * 9000); // Ensures a 4-digit number
    const orderId = `${year}${month}${day}${hours}${minutes}${seconds}${randomNum}`;
    setOrderId(orderId);
  };

  useEffect(() => {
    getProduct();
  }, [params?.name]);

  useEffect(() => {
    generateOrderId();
  }, []);

  async function handleCheckPlayer() {
    if (userId === "" || zoneId === "") {
      return message.error(
        `${userId === "" ? "Enter user id" : "Enter zone id"}`
      );
    }
    try {
      const idPro = productId.split("&")[0];
      const object = {
        region: product?.region,
        userid: userId,
        zoneid: zoneId,
        productid: idPro,
      };
      const res = await axios.post("/api/payment/get-role", object);
      if (res.data.success) {
        setPlayerCheck(res.data.username);
        setLoading(false);
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  function checkPlaceOrder(e) {
    if (product?.fields === "2") {
      if (userId === "") {
        return message.error("Some Fields are missing");
      }
      if (zoneId === "") {
        return message.error("Some Fields are missingx");
      }
    } else if (product?.fields === "1") {
      if (userId === "") {
        return message.error("Some Fields are missingx");
      }
    }
    if (product?.playerCheck === "yes") {
      if (playerCheck === null) {
        return message.error("Check Username");
      }
    }
    if (product.api === "yes") {
      if (mode === "UPI") {
        handleSmileOneUpiOrder(e);
      } else {
        handleSmileOneWalletOrder(e);
      }
    } else {
      if (mode === "UPI") {
        handleUpiOrder(e);
      } else {
        handleWalletOrder(e);
      }
    }
  }

  // smilone
  const handleSmileOneUpiOrder = async (e) => {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.region,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        // callback_url: `https://thoihenofficial.com/api/smile/status?orderId=${orderId}`,
        // callback_url: `http://localhost:3000/confirmation?orderId=${orderId}`,
        callback_url: `https://thoihenofficial.com/confirmation?orderId=${orderId}&type=auto`,

        txn_note:
          userId +
          "@" +
          zoneId +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount,
      };

      setLoader(true);
      const response = await axios.post("/api/smile/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.result.payment_url;
        setLoading(false);
      } else {
        message.error(response.data.message);
        setLoading(false);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  const handleSmileOneWalletOrder = async (e) => {
    if (parseInt(balance) < parseInt(selectedPrice)) {
      return message.error("Balance is less for this order");
    }
    e.preventDefault();
    try {
      const orderObject = {
        orderId: orderId,
        userid: userId,
        zoneid: zoneId,
        productid: productId,
        region: product.region,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        pname: product?.name,
        amount: amount,
        price: selectedPrice,
      };
      setLoading(true);
      setLoader(true);
      const res = await axios.post("/api/smile/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/user-dashboard");
        setLoading(false);
      } else {
        setLoading(false);
        setLoader(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  // manual
  const handleUpiOrder = async (e) => {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.name,
        customer_name: user?.fname,
        customer_mobile: user?.mobile,
        customer_email: user?.email,
        // callback_url: `http://localhost:3000/api/manual/status?orderId=${orderId}`,
        callback_url: `https://thoihenofficial.com/confirmation?orderId=${orderId}`,
        txn_note: userId + "@" + zoneId + "@" + product?.name + "@" + amount,
      };
      setLoader(true);
      const response = await axios.post("/api/manual/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.result.payment_url;
        setLoading(false);
        setLoader(false);
      } else {
        message.error(response.data.message);
        setLoading(false);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  const handleWalletOrder = async (e) => {
    if (parseInt(balance) < parseInt(selectedPrice)) {
      return message.error("Balance is less for this order");
    }
    e.preventDefault();
    try {
      const orderObject = {
        api: "no",
        orderId: orderId,
        userid: playerId,
        customer_email: user && user?.email,
        customer_mobile: user && user?.mobile,
        pname: product?.name,
        amount: amount,
        price: selectedPrice,
      };
      setLoading(true);
      setLoader(true);
      const res = await axios.post("/api/manual/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setLoading(false);
        setLoader(false);
        message.success(res.data.message);
        navigate("/orders");
        localStorage.setItem("orderProcess", "no");
      } else {
        message.error(res.data.message);
        setLoading(false);
        setLoader(false);
        localStorage.setItem("orderProcess", "no");
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
      setLoading(false);
      localStorage.setItem("orderProcess", "no");
    }
  };

  return (
    <Layout>
      {loader ? (
       <div className="loading-container">
         <div class="spinner"></div>
         <h4>Order processing..</h4>
       </div>
     ) : (
        <div>
          <div
            className="p-info-container area"
            style={{
              backgroundImage: `url(https://thoihenofficial.com/${product?.bannerImage?.replace(
                /\\/g,
                "/"
              )})`,
              backgroundSize: "cover",
              backgroundPosition: "top",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="pro-img">
              <img
                src={`https://thoihenofficial.com/${product?.image}`}
                alt=""
              />
              <div>
                <h2 className="m-0">{product?.name}</h2>
                <h6 className="m-0 d-none d-md-none d-lg-block">
                  {product?.descTwo}
                </h6>
                <div className="features">
                  <button>
                    <SupportAgentIcon className="icon" />
                    24 Customer Service
                  </button>
                  <button>
                    <AssuredWorkloadIcon className="icon" />
                    Safe Payment
                  </button>
                  <button>
                    <VerifiedUserIcon className="icon" />
                    Official Store
                  </button>
                  <button>
                    <WorkspacePremiumIcon className="icon" />
                    Service Guarantee
                  </button>
                  <button>
                    <ElectricBoltIcon className="icon" />
                    Instant Delivery
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="instruction-image">
            <button onClick={() => setPopup(!popup)}>
              <InfoIcon className="icon me-1" />
              How to Purchase
            </button>
            <div className={`ins-popup ${popup && "active"}`}>
              <img
                src={`https://thoihenofficial.com/${product?.instructionImage}`}
                alt=""
              />
              <CancelIcon
                className="icon close-icon"
                onClick={() => setPopup(!popup)}
              />
            </div>
          </div>
          <div className="package-details">
            <div className="package-container">
              {product?.cost?.map((item, index) => {
                return (
                  <div
                    onClick={() => {
                      setAmount(item.amount);
                      setPriceAndId(item.amount);
                      window.scrollTo({
                        top: document.documentElement.scrollHeight / 2, // Scrolls to 50% of the page height
                        behavior: 'smooth',
                      });
                    }}
                    key={index}
                    className={`amount ${amount === item?.amount && "active"}`}
                  >
                    <span>
                      <img src={item?.pimg} alt="pimg" />
                      <small>{item.amount}</small>
                    </span>
                  </div>
                );
              })}
            </div>
            {/* ================================= FIELDS  */}
            {/* ================================= FIELDS  */}
            {/* ================================= FIELDS  */}
            <div className="order-info">
              <div className="pack-info">
                <div className="order-information-title">
                  Order Information
                  <button className="info-btn" onClick={togglePopup}>
                    ?
                  </button>

                {popupVisible && (
                  <div className="popup-container">
                    <div className="popup-content">
                      <span className="close-btn" onClick={togglePopup}>
                        ×
                      </span>
                      <img src="/id.png" alt="Order Information" />
                    </div>
                  </div>
                )}
                </div>

                {product?.fields === "2" ? (
                  <>
                    <div className="d-flex align-items-center">
                      <input
                        className="player-tag"
                        type="text"
                        name="userId"
                        placeholder={`${product?.tagOne}`}
                        onChange={(e) => setUserId(e.target.value)}
                        value={userId}
                      />
                    </div>
                    <input
                      className="player-tag text-white"
                      type="text"
                      name="zoneid"
                      placeholder={`${product?.tagTwo}`}
                      onChange={(e) => setZoneId(e.target.value)}
                      value={zoneId}
                    />
                  </>
                ) : (
                  <input
                    className="player-tag"
                    type="text"
                    name="userId"
                    placeholder={`${product?.tagOne}`}
                    onChange={(e) => setUserId(e.target.value)}
                    value={userId}
                  />
                )}
                {product?.playerCheck === "yes" && !playerCheck && (
                  <button className="p-check-btn" onClick={handleCheckPlayer}>
                    Check Username
                    {loading && (
                      <div
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                )}
                {playerCheck && (
                  <div className="playername">
                    {playerCheck && "Username: " + playerCheck}
                    <VerifiedIcon className="icon" />
                  </div>
                )}
              </div>
              <div className="mt-4 pack-info">
                <div className="title">
                  <span>Total</span>
                  <div className="price ">
                    {selectedPrice !== null ? (
                      <h3 className="m-0 mt-3">
                        <b>Rs. {selectedPrice}</b>
                      </h3>
                    ) : (
                      "Select an amount to see the price"
                    )}
                  </div>
                </div>
                {!user ? (
                  <button
                    className="p-check-btn"
                    onClick={() => navigate("/login")}
                  >
                    Please Login
                  </button>
                ) : product?.stock === "no" ? (
                  <button className="p-check-btn" style={{ opacity: "0.7" }}>
                    Out of Stock
                  </button>
                ) : (
                  <button onClick={checkPlaceOrder} className="p-check-btn">
                    Buy Now
                  </button>
                )}
              </div>
            </div>
            {/* ================================= FIELDS  */}
            {/* ================================= FIELDS  */}
            {/* ================================= FIELDS  */}

            <div className="pro-desc-container">
              <div className="pro-desc">
                <span>{product?.desc}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default ProductInfo;
