import React, { useEffect, useRef, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import { message } from "antd";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import "./AdminGallery.css";

const AdminGallery = () => {
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [images, setImages] = useState(null);

  function handleFileChange(e) {
    setFile(e.target.files[0]);
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        alert("Link copied!");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("image", file);

      const res = await axios.post("/api/image/upload", formData);
      if (res.data.success) {
        message.success(res.data.message);
        getAllImages();
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handleDelete(id) {
    const confirm = window.confirm("Are you sure to delete?");
    if (confirm) {
      try {
        const res = await axios.post("/api/image/delete", { id });
        if (res.data.success) {
          message.success(res.data.message);
          getAllImages();
        } else {
          message.error(res.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  async function getAllImages() {
    try {
      const res = await axios.get("/api/image/get-images");
      if (res.data.success) {
        setImages(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAllImages();
  }, []);

  return (
    <AdminLayout>
      <div className="admin-gallery-container">
        <div className="page-title">
          <h3 className="m-0">Gallery</h3>
        </div>
        <hr />
        <div className="gallery-container">
          <div className="form-fields col-12 text-dark">
            <input
              className="w-100 form-control"
              aria-label="Select Image"
              type="file"
              accept=".jpg, .jpeg, .png, .webp"
              name="image"
              required
              ref={fileInputRef}
              onChange={handleFileChange}
            />
            <button onClick={handleSubmit} className="button">
              Upload
            </button>
          </div>
        </div>

        {/* Table View */}
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Image</th>
                <th>Image Path</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {images?.map((item, index) => {
                const imageUrl = `https://thoihenofficial.com/${item?.image}`;
                return (
                  <tr key={item._id}>
                    <td data-label="Sr No">{index + 1}</td>
                    <td data-label="Image"className="td-image"
                    >
                      <img src={imageUrl} alt="Gallery Image" 
                      className="table-image"/>
                    </td>
                    <td data-label="Image Path">
                      <a
                        href={imageUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {imageUrl}
                      </a>
                      <button
                        onClick={() => copyToClipboard(imageUrl)}
                        style={{
                          marginLeft: "10px",
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                          color: "#007bff",
                        }}
                      >
                        <p>Copy</p>
                      </button>
                    </td>
                    <td data-label="Action">
                      <DeleteIcon onClick={() => handleDelete(item._id)} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {/* Mobile Card View */}
        <div className="gallery-card-container">
          {images?.map((item, index) => {
            const imageUrl = `https://thoihenofficial.com/${item?.image}`;
            return (
              <div key={item._id} className="gallery-card">
                <img src={imageUrl} alt="Gallery Image" className="gallery-image" />
                <div className="gallery-info">
                  <button
                    onClick={() => copyToClipboard(imageUrl)}
                    className="copy-button"
                  >
                    Copy Link
                  </button>
                  <DeleteIcon onClick={() => handleDelete(item._id)} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminGallery;