import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import { message } from "antd";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./AdminProducts.css";

const AdminProduct = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(null);

  // delete product
  const handleDeleteProduct = async (id, imagepath) => {
    const shouldDelete = window.confirm("Are you sure to delete?");
    if (shouldDelete) {
      try {
        const res = await axios.post(
          "/api/product/delete-product",
          {
            id,
            imagepath,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        if (res.data.success) {
          getAllProducts();
          message.success(res.data.message);
        } else {
          message.error(res.data.message);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  // Search
  const handleSearch = () => {
    if (searchQuery.trim() === "") {
      setFilteredUsers(null);
    } else {
      const filtered = products.filter((product) => {
        return product?.name.toLowerCase().includes(searchQuery.toLowerCase());
      });
      setFilteredUsers(filtered);
    }
  };

  // get all products
  const getAllProducts = async () => {
    try {
      const res = await axios.get("/api/product/get-all-products");
      if (res.data.success) {
        setProducts(res.data.data.reverse());
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleSearch();
  }, [searchQuery, products]);

  useEffect(() => {
    getAllProducts();
  }, []);

  const filterProduct =
    filteredUsers && filteredUsers ? filteredUsers : products;

  return (
    <AdminLayout>
      <div className="admin-product-page-container">
        <div className="page-title">
          <h3>Products</h3>
          <button
            onClick={() => navigate("/admin-add-product")}
            style={{ backgroundColor: "black", color: "white" }}
          >
            Add New Product
          </button>
        </div>
        <hr />
        <div className="tools">
          <div>
            <SearchIcon className="text-dark me-2" />
            <input
              className="mb-4"
              type="search"
              name="search"
              placeholder="Search by name"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>

        {/* Table View */}
        <div className="table-container">
          <table className="table product-table">
            <thead>
              <tr>
                <th>Image</th>
                <th>Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filterProduct?.map((product, index) => (
                <tr key={index}>
                  <td data-label="Image">
                    <img
                      src={`https://thoihenofficial.com/${product?.image}`}
                      alt=""
                      className="table-image"
                    />
                  </td>
                  <td data-label="Name">
                    <small>{product?.name}</small>
                  </td>
                  <td data-label="Action">
                    <div className="d-flex gap-2">
                      <EditIcon
                        onClick={() =>
                          navigate(`/admin-edit-product/${product?._id}`)
                        }
                      />
                      <DeleteIcon
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleDeleteProduct(product?._id, product?.image)
                        }
                        className="text-danger"
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Mobile Card View */}
        <div className="product-card-container">
          {filterProduct?.map((product, index) => (
            <div key={index} className="product-card">
              <img
                src={`https://thoihenofficial.com/${product?.image}`}
                alt=""
                className="product-image"
              />
              <div className="product-info">
                <div><strong>Name:</strong> {product?.name}</div>
                <div className="action">
                  <EditIcon
                    onClick={() => navigate(`/admin-edit-product/${product?._id}`)}
                    className="text-muted"
                  />
                  <DeleteIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => handleDeleteProduct(product?._id, product?.image)}
                    className="text-danger"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminProduct;