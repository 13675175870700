import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import "./Orders.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";

const Orders = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [allOrders, setAllOrders] = useState(null);
  const [loading, setLoading] = useState(false);

  const getAllUserOrders = async () => {
    try {
      setLoading(true);
      const res = await axios.post(
        "/api/order/get-user-orders",
        { email: user?.email },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setAllOrders(res.data.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (user !== null) {
      getAllUserOrders();
    }
  }, [user]);

  return (
    <Layout>
      <DashboardLayout>
        <div className="user-order-container">
          {loading ? (
            <div className="loader-container text-center">
              <span className="loader"></span>
            </div>
          ) : allOrders && allOrders?.length === 0 ? (
            <div className="no-order-found">
              <b>No Order Found</b>
              <button
                className="btn text-decoration-underline"
                onClick={() => navigate("/phone-skins")}
              >
                Go to Shop
              </button>
            </div>
          ) : (
            <div className="orders-table-container">
              <table className="orders-table">
                <thead>
                  <tr>
                    <th>Order ID</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Total</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allOrders?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td data-label="Order ID">{item?.orderId}</td>
                        <td data-label="Date">
                          {item?.createdAt
                            ? new Date(item.createdAt).toLocaleDateString(
                                "en-US",
                                { year: "numeric", month: "long", day: "numeric" }
                              )
                            : ""}
                        </td>
                        <td data-label="Status">{item?.status}</td>
                        <td data-label="Total">{item?.price}</td>
                        <td data-label="Action" className="action-td">
                          <button
                            onClick={() =>
                              navigate(`/view-order/${item?.orderId}`)
                            }
                            className="view-btn"
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default Orders;
