import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import axios from "axios";
import { useParams } from "react-router-dom";
import { message } from "antd";
import "./ViewOrder.css";

const ViewOrder = () => {
  const params = useParams();
  const [singleOrder, setSingleOrder] = useState(null);

  const getOrderById = async () => {
    try {
      const res = await axios.post(
        "/api/order/get-order-by-id",
        {
          orderId: params?.orderId,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setSingleOrder(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOrderById();
  }, []);

  return (
    <Layout>
      <DashboardLayout>
        <div className="view-order-summary">
          <span>
            Order #{singleOrder?.orderId} was placed on{" "}
            {singleOrder?.createdAt
              ? new Date(singleOrder.createdAt).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })
              : ""}{" "}
            and is currently <strong>{singleOrder?.status}</strong>.
          </span>
        </div>

        <div className="view-order-details">
          <div className="order-item">
            <span className="label">Order ID</span>
            <span className="value">{singleOrder?.orderId}</span>
          </div>
          <div className="order-item">
            <span className="label">Product</span>
            <span className="value">{singleOrder?.p_info}</span>
          </div>
          <div className="order-item">
            <span className="label">Price</span>
            <span className="value">Rs. {singleOrder?.price}</span>
          </div>
          <div className="order-item">
            <span className="label">Status</span>
            <span className="value">{singleOrder?.status}</span>
          </div>
          <div className="order-item">
            <span className="label">Amount</span>
            <span className="value">{singleOrder?.amount}</span>
          </div>
          <div className="order-item">
            <span className="label">Player ID</span>
            <span className="value">{singleOrder?.userId}</span>
          </div>
          <div className="order-item">
            <span className="label">Date</span>
            <span className="value">
              {singleOrder?.createdAt
                ? new Date(singleOrder.createdAt).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })
                : ""}
            </span>
          </div>
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default ViewOrder;
